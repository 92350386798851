import React from 'react';
import { Link } from 'react-router-dom';

import './ErrorPage.css';

function ErrorPage(props) {
    function goBack(e) {
        e.preventDefault();
        props.history.goBack()
    }

    function goMain(e) {
        e.preventDefault();
        props.history.push('/')
    }

    return (
        <div class="d-flex align-items-center flex-column justify-content-center h-100 bg-dark text-white" id="header">
            <h1 className="error-text">Ошибка 404</h1>
            <p className="error-text">
                <Link onClick={goBack} className="error-link">&larr;&thinsp;Назад</Link> &middot; <Link onClick={goMain} className="error-link">На главную</Link>
            </p>
        </div>
    )
}

export default ErrorPage; 