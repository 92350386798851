import 'react-app-polyfill/ie11'; // должно быть первой строкой
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { apiRequest } from './deepApi'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Page from './components/Page';
import ErrorPage from './components/ErrorPage';

const rootElement = document.getElementById("root");

apiRequest(null, 'api-cms-menu-get', (res) => {
  let menu = res.MenuSections;

  menu.push({
    name: "",
    route: "",
    pages:
      [
        {
          name: "Контакты",
          route: "contacts"
        }]
  });

  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
      <Router>
        <Switch>
          <Route exact path="/"
            render={(props) => <App {...props} menu={menu} />} />
          {menu.map((section, i) =>
            section.pages.map((page, j) => {
              return section.name === "" ?
                <Route
                  exact path={`/${page.route}`}
                  render={(props) => <Page {...props} section={section} currentPage={page} menu={menu} />}
                /> :
                <Route
                  exact path={"/" + section.route + "/" + page.route}
                  render={(props) => <Page {...props} section={section} currentPage={page} menu={menu} />}
                />
            }))}
          <Route render={(props) => <ErrorPage {...props} />} />
        </Switch>
      </Router>, rootElement);
  } else {
    ReactDOM.render(
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => <App {...props} menu={menu} />} />
          {menu.map((section, i) =>
            section.pages.map((page, j) => {
              return section.name === "" ?
                <Route
                  exact path={`/${page.route}`}
                  render={(props) => <Page {...props} section={section} currentPage={page} menu={menu} />}
                /> :
                <Route
                  exact path={"/" + section.route + "/" + page.route}
                  render={(props) => <Page {...props} section={section} currentPage={page} menu={menu} />}
                />
            }))}
          <Route render={(props) => <ErrorPage {...props} />} />
        </Switch>
      </Router>, rootElement);
  }
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
