import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import ScrollUpButton from "react-scroll-up-button";
import Project from './Project';
import AskQuestion from './AskQuestion'
import Circle from './Circle';
import Image from 'react-bootstrap/Image';

import { Helmet } from 'react-helmet'
import { COMPANY_NAME } from '../deepApi'

import './Page.css';
import './MainPage.css';

function MainPage() {

  let projects = [
    { name: "object1", caption: "Талаховская ТЭЦ" },
    { name: "object2", caption: "Паркинг «Золотая Звезда»" },
    { name: "object3", caption: "Паркинг «Ривер Парк»" },
    { name: "object4", caption: "Гаражный комплекс" },
    { name: "object5", caption: "Паркинг ул. Большая Очаковская" },
    { name: "object6", caption: "ФКП завод имени Я.М.Свердлова" }];

  let circles = [
    { 
      image: "flame", 
      caption: "Огнезащита конструкций", 
      text: "Воздуховоды, металлические, железобетонные и деревянные конструкции", 
      link: "fire-protection/protection"
    },
    { 
      image: "megaphone", 
      caption: "Системы пожаротушения", 
      text: "Оповещение, сигнализация, дымоудаление", 
      link: "fire-protection/automated-fire-extinguishing-systems",
    },
    { 
      image: "compose", 
      caption: "Разработка документации", 
      text: "Мероприятия по обеспечению пожарной безопасности, расчет пожарного риска",
      link: "project-documentation/documentation"
    },
    { 
      image: "genius",
      caption: "Комплексное сопровождение",
      text: "Консультации, подготовка, контроль",
      link: "maintenance/complex-construction-objects"
    }];

    let carItems = [
      {
        image: "slide-1-fire",
        topImage: "guard",
        caption: "Огнезащита",
        text: "Компания ООО «СоЭнерго-Строй» оказывает услуги по огнезащите металлических конструкций различной площади, геометрии и высотности",
      },
      {
        image: "slide-2-docs",
        topImage: "docs",
        caption: "Разработка документации",
        text: "Подготовка и сдача документации в испытательную пожарную лабораторию",
      },
      {
        image: "slide-3-complex",
        topImage: "complex",
        caption: "Комплексное сопровождение",
        text: "Долгосрочные отношений с партнерами, и комплексных подход к сопровождению строительных объектов",
      }
    ];

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME + ' - услуги по огнезащите'}</title>
      </Helmet>

      <Carousel>
        {carItems.map((c, i) => {
          return <Carousel.Item key={i}>
            <Image className="d-block"
              src={`/images/${c.image}.jpg`}
              alt={c.caption} />
            <Carousel.Caption>
              <Image
                src={`/images/${c.topImage}.png`}
                className='image-top img-fluid mx-auto d-block' />
              <h3>{c.caption}</h3>
              <span>{c.text}</span>
            </Carousel.Caption>
          </Carousel.Item>
        })}
      </Carousel>

      <main role="main"><Container>
        <Row>
          <Col xs>
            <p>
              «СоЭнерго — Строй» – строительная компания! Основным направлением нашей деятельности является оказание полного комплекса услуг по огнезащите зданий и конструкций. Сегодня мы крупная компания с многолетней историей, наш опыт и человеческие ресурсы позволяют предоставлять нашим клиентам наиболее эффективные решения в области пожарной безопасности. Профессиональный подход к каждому проекту гарантирует высокое качество исполнения работ на всех стадиях.</p>

            <Row className="circles">
              {circles.map((c, i) => { return <Circle key={i} image={c.image} caption={c.caption} text={c.text} link={c.link} /> })}
            </Row>

            <Card>
              <Card.Header>Консультация по услугам</Card.Header>
              <Card.Body>
                <p>Менеджеры компании с радостью ответят на ваши вопросы, произведут расчет стоимости услуг и подготовят индивидуальное коммерческое предложение.</p>
                <AskQuestion />
              </Card.Body>
            </Card>

            <Row className="products-header">
              <Col>
                <h4>Реализованные проекты</h4>
              </Col>
            </Row>
            <Row className="products">
              {projects.map((project, i) => { return <Project key={i} name={project.name} caption={project.caption} /> })}
            </Row>
          </Col>
        </Row>
      </Container>
        <ScrollUpButton />
      </main>
    </>)
}

export default MainPage;