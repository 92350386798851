import React, { useState, useEffect } from 'react';
import './Footer.css';
import { Row, Col, Container } from 'react-bootstrap';
import { apiRequest } from '../deepApi';

function Footer() {
    let [company, setCompany] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");
    let [address1, setAddress1] = useState("");
    let [address2, setAddress2] = useState("");

    useEffect(() => {
        apiRequest(null, 'api-cms-company-get', (res) => {
            setCompany(res.CompanyNameShort);
            setEmail(res.CompanyEMail);
            setPhone(res.CompanyPhone);
            setAddress1(res.CompanyAdressLine1);
            setAddress2(res.CompanyAdressLine2);
        });
    }, []);

    return <footer className="footer footer-bs">
        <Container>
            <Row className="footer-text">
                <Col xs >
                    <p>&copy; 2022 {company}</p>
                </Col>
            </Row>
            <Row className="footer-text">
                <Col sm={3} className="footer-link">
                    <p><a href={`mailto:${email}`}>{email}</a><br />
                        <a href={`tel:${phone}`}>{phone}</a></p>
                </Col>
                <Col xs >
                    <p>{address1},<br />{address2}</p>
                </Col>
            </Row>
            <Row>
                <Col className="footer-link footer-creatorlink">
                    <p className="text-center text-muted small">
                        САЙТ РАЗРАБОТАН В <a href="https://tias.pro">ООО &laquo;ТИАС&raquo;</a>
                    </p>
                </Col>
            </Row>
        </Container>
    </footer>
}

export default Footer;