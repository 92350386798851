import React, { useState, useEffect } from 'react';
import './Page.css';
import './Project.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet'
import { COMPANY_NAME } from '../deepApi'
import { Link } from "react-router-dom";
import Contacts from './Contacts'
import Menu from './Menu'
import Footer from './Footer'

import ScrollUpButton from "react-scroll-up-button";
import { HashLink } from 'react-router-hash-link';

import { apiRequest } from '../deepApi'
import PageOrder from './PageOrder';


//-------

import PromoPage from '../single/PromoPage';

function Page(props) {
    let [content, setContent] = useState("eee");

    useEffect(() => {
        getContents(props.currentPage.deepID);
    }, [props.currentPage.deepID]);

    function getContents(id) {
        var req = {
            ID: id
        };

        apiRequest(req, 'api-cms-page-get', (res) => {
            setContent({ content: res.Content });
        });
    }


    let ret =
        <>
            
            <Menu sections={props.menu} elID="theMenu" menuBGcolor="light" />
            <Helmet>
                <title>{`${props.currentPage.name} — ${COMPANY_NAME}`}</title>
            </Helmet>
            <main role="main"><Container>
                <Row>
                    {
                        props.section.name === "" ? null :
                            <div className="col-lg-3 col-xl-3 d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block">
                                <h2 className="page-bs left-menu-text">{props.section.name}</h2>
                                <ul>
                                    {props.section.pages.filter(p => p.noshow !== 1).map((page) => {
                                        return props.currentPage.name === page.name ?
                                            <li key={page.route}><span className="left-menu-text">{page.name}</span></li> :
                                            <li key={page.route}>
                                                <Link
                                                    to={`/${props.section.route}/${page.route}`}
                                                    className="left-menu-link">{page.name}
                                                </Link>
                                            </li>
                                    })}
                                </ul>
                            </div>
                    }
                    <Col id="deep-content-top">
                        { /*<h3>{props.currentPage.name}</h3>*/}
                        {props.currentPage.route === "contacts" ?
                            <Contacts /> : <>{<div dangerouslySetInnerHTML={{ __html: content.content }} />}
                                {!!props.currentPage.deepService && <PageOrder service={props.currentPage.deepService} />}</>}
                    </Col>
                </Row>
                <Row>
                    <div className="col-xs-12 col-sm-12 col-md-12 d-block d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
                        <h2 className="page-bs left-menu-text">{props.section.name}</h2>
                        <ul>
                            {props.section.pages.filter(p => p.noshow !== 1).map((page) => {
                                return props.currentPage.name === page.name ?
                                    <li key={page.route}><span className="left-menu-text">{page.name}</span></li> :
                                    <li key={page.route}>
                                        <HashLink
                                            to={`/${props.section.route}/${page.route}#deep-content-top`}
                                            className="left-menu-link">{page.name}
                                        </HashLink>
                                    </li>
                            })}
                        </ul>
                    </div>
                </Row>
            </Container></main>
            <div>
                <ScrollUpButton />
            </div>
            <Footer />
        </>;


    if (props.currentPage.route === "reinforce")
    {
        ret = <>
            
            <PromoPage sections={props.menu} />
        
        </>;
    }

    return ret;
}

export default Page;