import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import MaterialOrderButton from './MaterialOrderButton.js';

class HeroText extends React.Component {
    render()
    {
        let html = 
        <>
        <div className="hero-text">
            <Row>
                <Col>                
                </Col>
            </Row>
            <h1 className="shad2">
                Компания СоЭнерго-Строй <br></br>
                реализует все виды работ в сфере <br></br>
                проектирования и монтажа <br></br>
                сверхпрочных систем усиления
            </h1>
            <br></br>
            
        </div>
        <MaterialOrderButton buttonClass = "hero-button" variant="outline-primary" costfrom="Заказать" item_name="Комплексно" />
        {/* <Button className="hero-button" variant="outline-primary" onClick="">Заказать</Button> */}
        </>;

        return (html);
    }
}

export default HeroText;