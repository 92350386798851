import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Card } from 'react-bootstrap';

import MaterialOrderButton from './MaterialOrderButton';



// list of items
const list = [
    {
        id: 1,
        name: 'Углеродная лента FibArm',
        description: 'Однонаправленная высокопрочная углеродная лента различной плотности и ширины',
        imageURL: '/image-material/1_small.jpg',
        costfrom: 'Заказать от 18 650 ₽'
    },
    {
        id: 2,
        name: 'Эпоксидные системы',
        description: 'Двух компонентная эпоксидная смола с усовершенствованным составом с применением композитных ламелей',
        imageURL: '/image-material/2_small.jpg',
        costfrom: 'Заказать от 25 875 ₽'
    },
    {
        id: 3,
        name: 'Композитные шпунтовые сваи',
        description: 'Сваи нового поколения, выполненные методом пултрузии высокого давления',
        imageURL: '/image-material/3_small.jpg',
        costfrom: 'Заказать от 11 000 ₽'
    },
    {
        id: 4,
        name: 'Углеродная ламель',
        description: 'Пропитанное в заводских условиях углеродное волокно для усиления большепролетных конструкций',
        imageURL: '/image-material/4_small.jpg',
        costfrom: 'Заказать от 149 800 ₽'
    },
    {
        id: 5,
        name: 'Углеродная сетка',
        description: 'Двунаправленная сетка из углеродных волокон для ремонта и усиления строительных элементов',
        imageURL: '/image-material/5_small.jpg',
        costfrom: 'Заказать от 134 250 ₽'
    },
    {
        id: 6,
        name: 'Двунаправленные углеродные ткани',
        description: 'Материал с высокой прочностью на растяжение, отличается стойкостью к любым агрессивным средам',
        imageURL: '/image-material/6_small.jpg',
        costfrom: 'Заказать от 130 000 ₽'
    },
    {
        id: 7,
        name: 'Огнезащита',
        description: 'Толстослойный состав для несущих конструкций, усиленных с применением системы армирования FibArm',
        imageURL: '/image-material/7_small.jpeg',
        costfrom: 'Заказать от 15 300 ₽'
    },
    {
        id: 8,
        name: 'Углеродные анкерные жгуты',
        description: 'Изготавливаются из углеродных волокон по технологии вязального плетения',
        imageURL: '/image-material/8_small.jpg',
        costfrom: 'Заказать от 10 000 ₽'
    },
    {
        id: 9,
        name: 'Углеродная и ПАН-Фибра',
        description: 'Молотое, рубленное или измельченное углеродное волокно',
        imageURL: '/image-material/9_small.jpg',
        costfrom: 'Заказать от 1 612 ₽'
    },
    {
        id: 10,
        name: 'Модифицированные дорожные покрытия',
        description: 'Полимерный материал с высокими физико-механическими характеристиками замещающий бетонные плиты',
        imageURL: '/image-material/10_small.jpg',
        costfrom: 'Заказать от 9 500 ₽'
    }
];

const SlideItem = ({ name, description, imageURL, costfrom, selected, parentState }) => {
    return <div
        //className={`deep-slide-item ${selected ? 'active' : ''}`}
        className={`deep-slide-item`}
    >
        <Card>
            <Card.Header><b>{name}</b></Card.Header>
            <Card.Body style={{ height: 470 + 'px' }}>
                <div style={{ height: 300 + 'px' }} draggable="false" >
                    <center>
                        <div style={{ backgroundImage: "url(" + imageURL + ")", backgroundRepeat: "no-repeat", backgroundSize: 100+'%', width: 300+'px', height: 300+'px'}} ></div>
                        {/* <img className="selectDisable" style={{ margin: 'auto' }} src={imageURL} alt={name} ></img> */}
                    </center>
                </div>
                <p>{description}</p>
                <MaterialOrderButton costfrom={costfrom} item_name={name}/>
                {/* <Button style={{ position: 'absolute', bottom: 20 + 'px' }} onClick={parentState.modalIsShown = !parentState.modalIsShown}>{costfrom}</Button> */}
            </Card.Body>
        </Card>


    </div>;
};

export const SlideAllItems = (list, parentState) =>
    list.map(el => {
        const name = el.name;
        const id = el.id;
        const description = el.description;
        const imageURL = el.imageURL;
        const costfrom = el.costfrom;

        console.log("SlideAllItems=" + name);

        return <SlideItem name={name} key={id} description={description} imageURL={imageURL} costfrom={costfrom} parentState={parentState} />;
    });

const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};

const ArrowLeft = Arrow({ text: '<', className: 'scroll-slide-arrow arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'scroll-slide-arrow arrow-next' });

const selected = 1;



class MaterialCustomBlock extends React.Component {
    constructor(props) {
        super(props);

        //this.slideItems = SlideAllItems(list, this.handleShow);

        this.state = {
            selected: selected

        };
    }



    onSelect = key => {
        this.setState({ selected: 0 });
    }


    handleButClick(title)
    {
        console.log("custom click: " + title);
    }

    render() {
        //const { selected } = this.state;
        const slides = SlideAllItems(list, this.state); //this.slideItems;

        return (
            <>
                <div className="deep-slider-block">
                    <div className="deep-slider">
                        <ScrollMenu
                            data={slides}
                            //dragging={false}
                            alignCenter={true}
                            arrowLeft={ArrowLeft}
                            arrowRight={ArrowRight}
                            //selected={selected}
                            selected={false}
                            hideSingleArrow={true}
                            //onSelect={this.onSelect}
                            wheel={false}
                            transition={1.2}
                            useButtonRole={false}
                            inertiaScrolling={true}
                            inertiaScrollingSlowdown={0.5}
                        />
                    </div>
                </div>

                

            </>

        );
    }
}

export default MaterialCustomBlock;