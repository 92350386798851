import './Menu.css';

import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

import logo from './logo.svg';
import Callback from './Callback';
import AskQuestion from './AskQuestion'
import { ButtonGroup } from 'react-bootstrap';
import { apiRequest } from '../deepApi';

function Menu(props) {
    const [expanded, setExpanded] = useState(false);
    let [phone, setPhone] = useState("");

    useEffect(() => {
        apiRequest(null, 'api-cms-company-get', (res) => {
            setPhone(res.CompanyPhone);
        });
    }, []);

    function collapse() {
        setExpanded(false);
    }

    function toogle() {
        setExpanded(!expanded);
    }

    return <div className="border-bottom">
        <Navbar className="navbar-bs" expand="lg" expanded={expanded} onToggle={()=>{}} >
            {/* see https://github.com/react-bootstrap/react-bootstrap/issues/3141 */}
            <Link to={"/"}>
                <Navbar.Brand>
                    <img src={logo} width="80px" alt="СЭС" />
                </Navbar.Brand>
            </Link>

            <Navbar.Toggle onClick={() => toogle()} />
            <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="mr-auto">
                    {props.sections.map((section, i) => {
                        return section.name === "" ?
                            section.pages.map((page, j) =>
                                <Nav.Link
                                    as={Link}
                                    to={`/${page.route}/`}
                                    onClick={() => collapse()}
                                    key={j}
                                    bsPrefix="nav-link menu-link">
                                    {page.name}
                                </Nav.Link>) :
                            <NavDropdown
                                title={section.name}
                                id="basic-nav-dropdown"
                                key={i}
                                bsPrefix="nav-link menu-link">
                                {section.pages.filter(p => p.noshow !== 1).map((page, k) =>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={"/" + section.route + "/" + page.route + "/"}
                                        key={k} onClick={() => collapse()}>
                                        {page.name}
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                    })}
                </Nav>
                <Nav>
                    <Nav.Link href={`tel:${phone}`} bsPrefix="phone nav-link phone-link d-lg-none d-xl-block">{phone}</Nav.Link>
                </Nav>

                <Form inline>
                    {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />
                        <Button variant="outline-success">Search</Button>*/}

                    <ButtonGroup>
                        <AskQuestion />
                        <Callback />
                    </ButtonGroup>
                </Form>
            </Navbar.Collapse>
        </Navbar></div>
}

export default Menu;