import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import { OBJLoader } from './OBJLoader';

import { OrbitControls } from './OrbitControls';

import model from './model1.obj';

class DDDModel extends Component {

    componentDidMount() {
        // === THREE.JS CODE START ===
        //let width = document.documentElement.clientWidth;
        //let height = document.documentElement.clientWidth;

        let width = document.getElementById("thirdBlock").offsetWidth;
        let height = document.getElementById("thirdBlock").offsetHeight;

        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        var renderer = new THREE.WebGLRenderer();

        scene.background = new THREE.Color(0xffffff);

        renderer.setSize(width, height);

        //document.body.appendChild(renderer.domElement);
        this.mount.appendChild(renderer.domElement);
        renderer.domElement.classList.add("ddd-model");

        var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
        scene.add(ambientLight);

        var pointLight = new THREE.PointLight(0xffffff, 0.8);
        camera.add(pointLight);

        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = new THREE.MeshBasicMaterial({ color: 0xaabbcc });
        var cube = null;
        //var cube = new THREE.Mesh(geometry, material);
        //scene.add(cube);

        camera.position.z = 5;
        camera.position.y = 0;


        var controls = new OrbitControls(camera, renderer.domElement);

        //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

        controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        controls.dampingFactor = 0.05;

        controls.screenSpacePanning = false;

        controls.minDistance = 3;
        controls.maxDistance = 10;

        controls.enablePan = false;
        controls.enableZoom = false;

        controls.minPolarAngle = 0;//Math.PI / 2;
        controls.maxPolarAngle = Math.PI / 2;

        controls.enableRotate = true;
        controls.enableKeys = true;

        var obj = null;

        var loader = new OBJLoader();

        loader.load('/model1.obj',
            function (object) {
                //var material2 = new THREE.MeshBasicMaterial({ color: 0xaaaaaa });
                var material3 = new THREE.MeshPhongMaterial({ color: 0x000000, specular: 0x666666, emissive: 0x303030, shininess: 10, opacity: 0.9, transparent: true });
                obj = object;

                object.traverse(function (child) {

                    if (child.isMesh) child.material = material3;

                });

                //obj.material = material2;
                scene.add(obj);
                obj.position.y -= 2;
            },
            function (xhr) {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            function (error) {
                console.log('An error happened');
            }
        );

        var animate = function () {

            //let width = document.documentElement.clientWidth;
            //let height = document.documentElement.clientWidth;

            var elem = document.getElementById("thirdBlock");
            if (elem === null) {return;}
            let width = document.getElementById("thirdBlock").offsetWidth;
            let height = document.getElementById("thirdBlock").offsetHeight;

            const canvas = renderer.domElement;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            renderer.setSize(width, height);

            requestAnimationFrame(animate);

            controls.update();

            //cube.rotation.x += 0.01;
            if (cube) { cube.rotation.y += 0.005; }
            if (obj) { obj.rotation.y -= 0.005; }

            renderer.render(scene, camera);
        };

        animate();
        // === THREE.JS EXAMPLE CODE END ===
    }


    render() {
        return (
            <div>
                <div className="ddd-root" ref={ref => (this.mount = ref)}></div>
            </div>
        )
    }
}

export default DDDModel;