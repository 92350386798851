import React, { useState } from 'react';
import { Col } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link';

import './Circle.css'

function Circle(props) {
    const [hovered, setHovered] = useState(false);

    let image = `/images/rounds-svg/${props.image}-gray.svg`;
    let hover = `/images/rounds-svg/${props.image}-hover.svg`;

    return <Col xs={12} sm={6} md={6} lg={3} className="text-center">
        <HashLink to={`/${props.link}#deep-content-top`} className="circle-link">
            <div className="circle"
                onMouseOver={e => setHovered(true)}
                onMouseOut={e => setHovered(false)}
                onTouchStart={e => setHovered(true)}
                onTouchCancel={e => setHovered(false)}
                onTouchEnd={e => setHovered(false)}>
                <img src={hovered ? hover : image} className="circle-zoom"
                    alt={props.caption}
                    width={128} />
                <h4 className="circle-link">{props.caption}</h4>
                <p className="circle-link">{props.text}</p>
            </div>
        </HashLink>
    </Col>
}

export default Circle;