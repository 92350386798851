import React from 'react';

class ThirdBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="thirdBlock" className="deep-third-block bg-white-100">



<div className="container space-1 ">
  <div className="mb-9" style={{paddingBottom: 400+"px"}}>
    <div className="w-md-80 text-center mx-auto mb-5">
      <p className="lead shad" style={{paddingTop: 30+"px"}}>
        Для нас очень важно оказывать услуги качественно 
        и максимально удовлетворять потребности наших клиентов. 
        Мы благодарны за оказанное доверие 
        и высоко ценим положительные отзывы.</p>
    </div>
  </div>

  <div className="row justify-content-center text-center">
    <div className="col-4 col-md-2 mb-8">
        <img className="u-clients" src="https://fireguard-rus.ru/file/41" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
        <img className="u-clients" src="https://fireguard-rus.ru/file/43" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/44" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/45" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/46" alt="Image Description"></img>
    </div>

    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/47" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/48" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/49" alt="Image Description"></img>
    </div>

    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/193" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/194" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8">
      <img className="u-clients" src="https://fireguard-rus.ru/file/195" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8 mb-md-0">
      <img className="u-clients" src="https://fireguard-rus.ru/file/196" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8 mb-md-0">
      <img className="u-clients" src="https://fireguard-rus.ru/file/197" alt="Image Description"></img>
    </div>

    <div className="col-4 col-md-2 mb-8 mb-md-0">
      <img className="u-clients" src="https://fireguard-rus.ru/file/198" alt="Image Description"></img>
    </div>
    <div className="col-4 col-md-2 mb-8 mb-md-0">
      <img className="u-clients" src="https://fireguard-rus.ru/file/199" alt="Image Description"></img>
    </div>

  </div>

</div>



            </div>
        );
    }
}

export default ThirdBlock;