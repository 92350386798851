import React from 'react';
import { Button, Form, Modal, Alert, Spinner } from 'react-bootstrap';
import { apiRequest } from '../deepApi';


import Personal from '../components/Personal';


const action = 'Заказать';

class MaterialOrderButton extends React.Component {
    constructor(props) {
        super(props);

        let comment = 'Заказ по усилению конструкции: ' + props.item_name;

        this.state = {
            isShown: false,
            formServiceType: "заказ",
            formServiceCategory: this.props.service,
            formPreOrderComment: comment,
            formCustomerName: "",
            formCustomerCompany: "",
            formCustomerEMail: "",
            formCustomerTelephone: "",
            formValidated : false,
            isLoading: false,
            isSuccess: false,
            modalIsShown: false
         }; 
    }

    
    setName = value => {
        this.setState({ formCustomerName: value });
    }

    setCompany = value => {
        this.setState({ formCustomerCompany: value });
    }

    setPhone = value => {
        this.setState({ formCustomerTelephone: value });
    }

    setEmail = value => {
        this.setState({ formCustomerEMail: value });
    }

    setComment = value => {
        this.setState({ formPreOrderComment: value });
    }

    setValidated = value => {
        this.setState({ formValidated: value });
    }

    setIsLoading = value => {
        this.setState({ isLoading: value });
    }

    setIsSuccess = value => {
        this.setState({ isSuccess: value });
    }

    setModalIsShown = value => {
        this.setState({ modalIsShown: value });
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            this.request();
        }

        this.setValidated(true);
    };

    request() {
        var req = {
            ServiceType: this.state.formServiceType,
            ServiceCategory: this.state.formServiceCategory,
            PreOrderComment: this.state.formPreOrderComment,
            CustomerName: this.state.formCustomerName,
            CustomerCompany: this.state.formCustomerCompany,
            CustomerEMail: this.state.formCustomerEMail,
            CustomerTelephone: this.state.formCustomerTelephone
        };
        this.setIsLoading(true);
        apiRequest(req, "api-cms-preorder-set", this.requestCallback);
    }

    requestCallback = res =>{
        // Добавить проверку на код ответа
        if (res.StatusID >= 0) {
            this.setIsSuccess(true);
        }
        else {
            alert('Произошла ошибка');
        }
        this.setIsLoading(false);
    }

    handleShow = key => {
        console.log("state=" + this.state);
        if (this.state === undefined)
        {
            console.log("state=undef");
        }
        else
        {
            this.setModalIsShown(!this.state.modalIsShown);
        }
    }

    render()
    {
        let button_code =
            <Button className={""+this.props.buttonClass}
                    onClick={this.handleShow}>{this.props.costfrom}</Button>;

        if (this.props.buttonClass === '')
        {
            button_code = 
                <Button style={{ position: 'absolute', bottom: 20 + 'px' }} 
                        onClick={this.handleShow}>{this.props.costfrom}</Button>;
        }



        return (
            <>
                {button_code}

                {/* <Button className={""+this.props.buttonClass} 
                        style={{ position: 'absolute', bottom: 20 + 'px' }} 
                        onClick={this.handleShow}>{this.props.costfrom}</Button> */}

                <Modal show={this.state.modalIsShown} onHide={this.handleShow}>
                    <Form noValidate validated={this.state.formValidated} onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Заказать: {this.props.service}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!!this.state.isSuccess && <Alert variant='success'>Спасибо за заказ, {this.state.formCustomerName}!</Alert>}
                            <Form.Group>
                                <Form.Label>Как к вам обращаться</Form.Label>
                                <Form.Control type="text" className="form-control"
                                    value={this.state.formCustomerName}
                                    onChange={e => this.setName(e.target.value)}
                                    disabled={this.state.isSuccess}
                                    required />
                                <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Название организации</Form.Label>
                                <Form.Control type="text" className="form-control"
                                    value={this.state.formCustomerCompany}
                                    onChange={e => this.setCompany(e.target.value)}
                                    disabled={this.state.isSuccess}
                                    />
                                <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ваш телефон</Form.Label>
                                <Form.Control type="phone" className="form-control"
                                    value={this.state.formCustomerTelephone}
                                    onChange={e => this.setPhone(e.target.value)}
                                    disabled={this.state.isSuccess}
                                    required />
                                <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Электронная почта</Form.Label>
                                <Form.Control type="email" className="form-control"
                                    value={this.state.formCustomerEMail}
                                    onChange={e => this.setEmail(e.target.value)}
                                    disabled={this.state.isSuccess}
                                    />
                                <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Комментарий к заказу</Form.Label>
                                <Form.Control as="textarea" rows="5" className="form-control" value={this.state.formPreOrderComment}
                                    onChange={e => this.setComment(e.target.value)}
                                    disabled={this.state.isSuccess}
                                    required />
                                <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                            </Form.Group>
                            <Personal action={action} />
                        </Modal.Body>
                        <Modal.Footer>
                            {!!this.state.isLoading && <Spinner animation="border" size="sm" />}
                            <Button variant="primary" type="submit" disabled={this.state.isLoading || this.state.isSuccess}>{action}</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default MaterialOrderButton;