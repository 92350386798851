import React from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

function Personal(props) {
    return <Form.Group>
        <Form.Check
            defaultChecked={true}
            required
            label={
                <div>
                    <span>Нажимая кнопку «{props.action}», я принимаю условия </span>
                    <Link to='/company/contract' target='_blank'>соглашения на обработку персональных данных</Link>
                    <span>.</span>
                </div>}
        />
    </Form.Group>
}

export default Personal;