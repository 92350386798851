import React from 'react';
import { Col, Image } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link';
import './Project.css'

function Project(props) {
    return <Col xs={12} sm={6} md={4} lg={2} className="text-center">
        <HashLink to={'company/projects#deep-content-top'} className="project-link">
            <div className="project">
                <Image className="image-zoom" src={`/images/${props.name}.jpg`} rounded width="120px" height="90px"></Image>
                <p className="project-caption">{props.caption}</p>
            </div>
        </HashLink>
    </Col>
}

export default Project;