import React from 'react';



//import './theme.css';

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

//import 'bootstrap/dist/css/bootstrap.min.css';

import 'normalize.css';

import '../components/Page.css';
import '../components/Project.css';

import './style.css';




//import BSMenu from './BSMenu.js';
import BGVideo from './BGVideo.js';
import HeroText from './HeroText.js';
import DDDModel from './DDDModel.js';
//import Footer from './Footer.js';

import MaterialCustomBlock from './MaterialCustomBlock.js';
import FirstBlock from './FirstBlock.js';
import SecondBlock from './SecondBlock.js';
import ThirdBlock from './ThirdBlock.js';

import BSMenu from './BSMenu';
import PromoFooter from './PromoFooter';

import ScrollUpButton from "react-scroll-up-button";


class PromoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { menuBGcolor: 'dark' }; 
    }

    updateMenuBGColor = (value) => { this.setState({ menuBGcolor: value })}

    render()
    {
        return (
            <>
                <BSMenu sections={this.props.sections}  elID="theMenu" menuBGcolor={this.state.menuBGcolor} />

                {/*<Menu sections={this.props.menu} 
                      elID="theMenu" 
                      menuBGcolor={this.state.menuBGcolor} />*/}
                
                <BGVideo />
                <HeroText />

                <MaterialCustomBlock />
                <FirstBlock />
                <SecondBlock />

                <ThirdBlock />
                <DDDModel />
                
                <PromoFooter />

                <MenuScrollFade elID="theMenu" updateMenuBGColor={this.updateMenuBGColor}/>

                <ScrollUpButton />
            </>
        );
    } 
}

export default PromoPage;


class MenuScrollFade extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    };
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = e => {
        let el = document.getElementById(this.props.elID);

        let offset = Number(window.pageYOffset);
        //console.log('window.pageYOffset=' + offset);
        if (offset > 50) {
            el.classList.add('block-animate');
            this.props.updateMenuBGColor("light");
        }
        else{
            el.classList.remove('block-animate');
            this.props.updateMenuBGColor("dark");
        }
    };

    render() {
        return (
            <div id = "scroll-fader"></div>
        );
    }
}

export { PromoPage };