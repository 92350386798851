import React, { useState, useEffect  } from 'react';
import './Page.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';
import Personal from './Personal';

import { apiRequest } from '../deepApi'

import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

function PageOrder(props) {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(undefined);
    const [validated, setValidated] = useState(false);
    let [companyPhone, setCompanyPhone] = useState("");

    function handleShow() {
        setIsShown(!isShown);
    }

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            request();
        }

        setValidated(true);
    };

    function request() {
        var req = {
            ServiceType: "заказ",
            ServiceCategory: props.service,
            PreOrderComment: comment,
            CustomerName: name,
            CustomerCompany: company,
            CustomerEMail: email,
            CustomerTelephone: phone
        };
        setIsLoading(true);
        apiRequest(req, "api-cms-preorder-set", requestCallback);
        ym('reachGoal','ses-mon-click');
    }

    function requestCallback(res) {
        // Добавить проверку на код ответа
        if (res.StatusID >= 0) {
            setIsSuccess(true);
        }
        else {
            alert('Произошла ошибка');
        }
        setIsLoading(false);
    }

    useEffect(() => {
        apiRequest(null, 'api-cms-company-get', (res) => {
            setCompanyPhone(res.CompanyPhone);
        });
    }, []);

    const action = 'Заказать';

    return <>
        <YMInitializer accounts={[55228135]} />
        <Card>
            <Card.Header>Подготовка заказа</Card.Header>
            <Card.Body>
                <Card.Text>
                Заказать услуги можно через наш Центральный офис по телефону <a href={`tel:${companyPhone}`}>{companyPhone}</a> или нажав на кнопку &laquo;{action}&raquo;, через специальную форму подготовки заказа. Наш специалист свяжется для консультации и ответит на все интересующие вас вопросы.
                </Card.Text>
                <Button variant="primary" onClick={handleShow}>{action}</Button>
            </Card.Body>
        </Card>

        <Modal show={isShown} onHide={handleShow}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Заказать: {props.service}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!isSuccess && <Alert variant='success'>Спасибо за заказ, {name}!</Alert>}
                    <Form.Group>
                        <Form.Label>Как к вам обращаться</Form.Label>
                        <Form.Control type="text" className="form-control"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Название организации</Form.Label>
                        <Form.Control type="text" className="form-control"
                            value={company}
                            onChange={e => setCompany(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ваш телефон</Form.Label>
                        <Form.Control type="phone" className="form-control"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Электронная почта</Form.Label>
                        <Form.Control type="email" className="form-control"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Комментарий к заказу</Form.Label>
                        <Form.Control as="textarea" rows="5" className="form-control" value={comment}
                            onChange={e => setComment(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">Обязательное поле</Form.Control.Feedback>
                    </Form.Group>
                    <Personal action={action} />
                </Modal.Body>
                <Modal.Footer>
                    {!!isLoading && <Spinner animation="border" size="sm" />}
                    <Button variant="primary" type="submit" disabled={isLoading || isSuccess}>{action}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default PageOrder;