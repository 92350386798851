import React from 'react';
import Menu from './components/Menu'
import './App.css';
import Footer from './components/Footer';
import MainPage from './components/MainPage';

function App(props) {
  return (
  <>
    <Menu sections={props.menu} />
    <MainPage />
    <Footer />
  </>
  );
}

export default App;
