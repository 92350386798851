import React from 'react';
import { Link } from "react-router-dom";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from 'react-bootstrap';

import Callback from '../components/Callback';
import AskQuestion from '../components/AskQuestion';


import logo from './logo.svg';

import '../components/Menu.css';

const phone = "8 800 511-39-50";



class BSMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false }; 
    }

    collapse = (value) => { this.setState({ expanded: false })}
    toogle = (value) => { this.setState({ expanded: !this.state.expanded })}

    render() {
        let html =
        <div>
            <Navbar className="sticky-nav" 
                    style={{borderBottom: '0px solid gray', boxShadow: '0px 0px 10px 0px #000000'}}
                    bg={this.props.menuBGcolor} variant={this.props.menuBGcolor}
                    expand="md" id={this.props.elID} >
                <Navbar.Brand href="/">
                    <img
                        src={logo} //"logo.svg"
                        width="80"
                        //height="50"
                        className="d-inline-block align-top logo-glow"
                        alt="СоЭнерго-Строй"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    {this.props.sections.map((section, i) => {
                        return section.name === "" ?
                            section.pages.map((page, j) =>
                                <Nav.Link
                                    as={Link}
                                    to={`/${page.route}/`}
                                    onClick={() => this.collapse()}
                                    key={j}
                                    bsPrefix="nav-link menu-link">
                                    {page.name}
                                </Nav.Link>) :
                            <NavDropdown
                                title={section.name}
                                id="basic-nav-dropdown"
                                key={i}
                                bsPrefix="nav-link menu-link">
                                {section.pages.filter(p => p.noshow !== 1).map((page, k) =>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={"/" + section.route + "/" + page.route + "/"}
                                        key={k} onClick={() => this.collapse()}>
                                        {page.name}
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                    })}

                        {/*<Nav.Link className="" href="#home">Home</Nav.Link>
                        <Nav.Link className="" href="#link">Link</Nav.Link>
                        <NavDropdown className="" title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item className="" href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item className="" href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item className="" href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className="" href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>*/}


                    </Nav>

                    <Nav>
                        <Nav.Link href={`tel:${phone}`} bsPrefix="phone nav-link phone-link d-lg-none d-xl-block">{phone}</Nav.Link>
                    </Nav>

                    <Form inline>
                        {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />
                            <Button variant="outline-success">Search</Button>*/}

                        <ButtonGroup>
                            <AskQuestion />
                            <Callback />
                        </ButtonGroup>
                    </Form>



                </Navbar.Collapse>
            </Navbar>
        </div>;

        return (html);
    }
}

export default BSMenu;