import React from 'react';
import webm from "./ses-umatex-900.webm";
import mp4 from "./ses-umatex-900.mp4";
import poster from "./ses-umatex-900.jpg";


class BGVideo extends React.Component {


    render()
    {
        let html = 

        //<VideoBg poster={poster}>
        //<VideoBg.Source src={webm} type="video/webm" />
        //<VideoBg.Source src={mp4} type="video/mp4" />
        //</VideoBg>;


        <div className="VideoContainer" >
            <video autoPlay="autoplay" loop="loop" muted playsInline className="Video" poster={poster}  >
                <source src={mp4} type="video/mp4" />
                <source src={webm} type="video/webm"/>
                {/*Your browser does not support the video tag.*/}
            </video>
        </div>;


        //<div className="out-video-back">
        //    <div className="out-video">
        //        <video autoPlay muted loop poster="mel.jpg" 
        //               id="bgvid" 
        //               width="100%" 
        //               height="auto"
        //               >
        //            <source src="Video1.webm" type="video/webm"/>
        //            <source src="Video1.mp4"  type="video/mp4"/>
        //        </video>
        //        <div className="main_text"></div>
        //    </div>
        //</div>;

        return(html);
    }
}

export default BGVideo;