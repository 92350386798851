import React from 'react';

import { Button, Popover, H5, Classes, Intent } from "@blueprintjs/core";

import img_coal from './coal.jpg';
import img_cgi_demo1 from './cgi-demo1.jpg';
import img_example1 from './example1.jpg';
import img_example2 from './example2.jpg';

class FirstBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div id="firstBlockRoot">
                <div className="container space-2">
                    <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9"
                         style={{marginTop: 50+'px', padding:50+'px'}}
                         >
                        <h4>Современные высокотехнологичные решения</h4>
<br></br>

                        <p>Мы восстанавливаем и усиливаем несущие конструкции зданий
                            и сооружений промышленных и гражданских объектов
                            с применением углеродных композитных материалов 
                            на базе системы внешнего армирования FibArm</p>
                        <p><br></br></p>
                    </div>

                    <div className="row justify-content-lg-between">
                        <div className="col-md-7">

                            <div className="row mx-gutters-2 mb-9 mb-md-0">

                                <div className="col-6 mb-3">
                                    <img className="img-fluid" src={img_coal} alt="Углерод"></img>
                                </div>

                                <div className="col-4 align-self-end mb-3">
                                    <img className="img-fluid" src={img_cgi_demo1} alt="CGI Example"></img>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-10 offset-2">
                                    <img className="img-fluid" src={img_example1} alt="Ленты"></img>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-4 mb-3" style={{marginTop: 10+'px'}}>
                                    <img className="img-fluid" src={img_example2} alt="Один из вариантов"></img>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-lg-4 "
                             style={{marginTop: 50+'px', marginBottom: 60+'px'}}
                             >
                            <h3 className="h5 text-center">Услуги</h3>


                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Проектирование" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Проектирование</H5>
                                    <p>
                                    Ключевой этап предшествующий непосредственно выполнению работ 
                                    по ремонту и усилению. 
                                    Ведь именно в этой стадии принимаются такие важные решения, 
                                    как выбор конструктивной схемы усиления и материала, 
                                    оптимального для решения поставленной задачи.
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>

                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Обследование" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Обследование</H5>
                                    <p>
                                    Позволяет получить необходимые данные, 
                                    выявить все слабые стороны несущей конструкции и 
                                    проанализировать варианты дальнейших действий.
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>

                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Усиление" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Усиление</H5>
                                    <p>
                                    Главной задачей усиления несущих конструкций зданий и 
                                    сооружений является противостояние силам, таким как сжатие, 
                                    крутящие моменты, поперечные силы, растяжение и другие, 
                                    которые воздействуют на строительные конструкции и 
                                    уменьшают их эксплуатационный срок. 
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>

                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Доармирование" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Доармирование</H5>
                                    <p>
                                    Проблема дефицита армирования распространена довольно широко. 
                                    Нехватка вертикальной арматуры приводит к продавливанию плиты перекрытия. 
                                    Причиной могут послужить ошибки как при проектировании, так и в процессе выполнения работ.
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>


                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Огнезащита" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Огнезащита</H5>
                                    <p>
                                    Нанесение на усиленные участки железобетонных конструкций 
                                    огнестойкого материала для формирования необходимого 
                                    по нормам пожарной безопасности теплоизолирующего слоя. 
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>


                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Сейсмоусиление" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Сейсмоусиление</H5>
                                    <p>
                                    Применение системы внешнего армирования углеволокном в сейсмоопасных районах 
                                    с уровнем сейсмичности 7-9 баллов позволяет уменьшать сейсмонагрузку 
                                    в 1,5–4 раза, в зависимости от конструкции объекта и условий площадки. 
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>


                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Инъектирование" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Инъектирование</H5>
                                    <p>
                                    Восстановление и ремонт железобетонных, каменных и кирпичных строительных конструкций 
                                    посредством нагнетания под высоким давлением сверхтекучих, 
                                    низковязких эпоксидных составов или микроцементов 
                                    в трещины через предварительно установленные пакеры.
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>



                            <br></br>

                            <div style={{marginTop: 20 +'px'}}>
                            <Popover position="left-top"
                                     popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                     >
                                <Button minimal={true} text="Шеф-монтаж" intent={Intent.PRIMARY} large={true} />

                                <div key="text" style={{width: 200+'px'}}>
                                    <H5>Шеф-монтаж</H5>
                                    <p>
                                    Выезд нашего специалиста на объект Заказчика с целью обучения монтажников Заказчика.
                                    </p>                                    
                                </div>
                            </Popover>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default FirstBlock;