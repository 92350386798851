import React from 'react';

import svg_1 from './genious-purple-icon.svg';
import svg_2 from './trophy-purple-icon.svg';

class SecondBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="deep-second-block">
                <div className="container space-2 space-3--lg">
                    <div className="row">
                        <div className="col-lg-6 mb-7 mb-lg-0">
                            <div className="media align-items-center">
                                <img className="max-width-18 mr-3 notformob" 
                                     width="150px"
                                     height="150px"
                                     src={svg_1} //"genious-purple-icon.svg" 
                                     alt="Инновационные материалы"></img>
                                <div className="media-body"
                                     style={{margin:20+'px'}}
                                     >
                                    <h3 className="h5">Высокотехнологичные материалы</h3>
                                    <p>Мы используем в производстве только высококачественное сырье, уделяем должное внимание совершенствованию качества продукции.</p>
                                    <a href="https://fireguard-rus.ru" target="_blank">Подробнее</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-7 mb-lg-0">
                            <div className="media align-items-center">
                                <img className="max-width-18 mr-3 notformob" 
                                     width="150px"
                                     height="150px"
                                     src={svg_2} //"trophy-purple-icon.svg" 
                                     alt="Гарантия качества"></img>
                                <div className="media-body"
                                     style={{margin:20+'px'}}
                                     >
                                    <h3 className="h5">Безупречное качество работ</h3>
                                    <p>Оказываем полный комплекса услуг по обеспечению безопасности зданий и сооружений, и полностью отвечает всем действующим стандартам и требованиям.</p>
                                    <a href="https://soenergo-stroy.ru" target="_blank">Подробнее</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecondBlock;