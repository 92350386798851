import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { apiRequest } from '../deepApi';

function Contacts() {
    let [company, setCompany] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");
    let [address, setAddress] = useState("");
    let [geo, setGeo] = useState([]);


    useEffect(() => {
        apiRequest(null, 'api-cms-company-get', (res) => {
            setCompany(res.CompanyNameFull);
            setEmail(res.CompanyEMail);
            setPhone(res.CompanyPhone);
            setAddress(res.CompanyAdress);
            setGeo(res.CompanyCoordinates.split(',').map(Number));
        });
    }, []);

    return <>
        <h2>{company}</h2>
        <p>{address}<br />
            <a href={`mailto:${email}`}>{email}</a><br />
            <a href={`tel:${phone}`}>{phone}</a>
        </p>

        <YMaps>
            <Map width="100%" height="400px" defaultState={{ center: geo, zoom: 16 }}>
                <Placemark geometry={geo} />
                <ZoomControl options={{ float: 'right' }} />
            </Map>
        </YMaps>
    </>
}

export default Contacts;